<template>
  <section class="cases-spinner">
    <Transition name="fade" mode="out-in" appear @before-enter="beforeTransition" @after-enter="afterTransition">
      <div v-if="showSpinner" ref="spinnerWrapper" :class="spinnerWrapperClasses">
        <div ref="line" :class="lineClasses"></div>
        <div v-if="isPossibleShadow" :class="{ 'drop-shadow': isAnimateDrop }"></div>
        <div ref="spinner" :class="spinnerClasses">
          <SharedKitDropItem
            v-for="(dropItem, idx) in dropsShow"
            :key="dropItem.id"
            :name="dropItem.name"
            :image="declareImageOnItem(dropItem)"
            :color="declareColorOfItem(dropItem)"
            :price="String(dropItem.price)"
            :class="itemClasses(idx)"
            :style="stylesItem(idx)"
          />
        </div>
      </div>
    </Transition>
    <RoundResultStatus
      v-if="isShowEndRoundCircle"
      :class="{ 'end-battle': isLastRound, 'end-round': true }"
      :time-for-change="BattlesSpinnerTimeouts.growShrinkEndRoundCircle"
      :time-stable="
        isLastRound ? BattlesSpinnerTimeouts.noChangeLastRoundCircle : BattlesSpinnerTimeouts.noChangeRoundCircle
      "
      :inner-color="innerColor"
      :outer-color="outerColor"
      :prize-line-color="prizeLineColor"
      :prize-line-show="isShowEndRoundRewardLine"
      :is-last-round="isLastRound"
      :round-prizes-percent-of-total="roundPrizesPercentOfTotal"
    >
      <SvgoBattlesEnd v-if="isLastRound" />
      <SvgoWonBattleIcon v-else-if="isWon" />
      <SvgoLoseBattleIcon v-else />
    </RoundResultStatus>
  </section>
</template>

<script setup lang="ts">
import RoundResultStatus from '../RoundResultStatus/RoundResultStatus.vue';
import type { ICasesSpinnerEmits, ICasesSpinnerProps } from './CasesSpinner.types';
import { useSpinnerAlgorithm } from '~/features/battles/composables/useSpinnerAlgorithm';
import type { TPossibleNull } from '~/types/Shared.types';
import { BattlesSpinnerTimeouts } from '~/features/battles/constants/rules';
import type { IRoundSkin } from '~/features/battles/types/battlesStore.types';
import { useEmitScroll } from '~/features/bk/components/BKCarouselCases/helper.function';
import { appConfig } from '~/config';
import { BattlesColors } from '~/features/battles/constants/colors';
import type { ISimplifiedPreviewItem } from '~/features/cases/types/case.types';

const SPINNER_ITEMS_ARRAY_MIN_LENGTH = 26;

const props = defineProps<ICasesSpinnerProps>();
const emits = defineEmits<ICasesSpinnerEmits>();

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const line = ref<HTMLElement | undefined>();
const spinner = ref<HTMLElement | undefined>();
const spinnerWrapper = ref<HTMLElement | undefined>();

const needToRoll = ref(false);

const spinnerEnd = ref(false);
const showDrop = ref(false);
const showDropTimeout = ref<TPossibleNull<ReturnType<typeof setTimeout>>>(null);
// скрываем рулетку после ухода дропа, до начала следующего прокрута
const isSpinnerHidden = ref(false);

const showSpinner = computed(() => !showDrop.value && !spinnerEnd.value);

// функция финала для отрисовки финального UI
const showEnd = computed(() => {
  if (!props.drops.length) {
    return false;
  }

  return spinnerEnd.value;
});

const { start: startSumAnimation, count: roundSum } = useCountAnimation({
  duration: BattlesSpinnerTimeouts.roundEndAnimation,
  withPrecision: true,
});

// Рандомное количество предметов после выпадающего
const ITEMS_AFTER_END = Math.round(Math.random() * 3 + 2);

// Отображемый перемешанный список предметов
const dropsShow = computed(() => {
  const newList = shuffle();

  if (!props.drops[0]) return newList;
  const index = newList.findIndex((drop) => drop.id === props.drops[0]!.id);

  const drop = newList.splice(index, 1)[0];
  newList.splice(-ITEMS_AFTER_END, 0, drop);

  return newList;
});

const lootPrice = computed(() => {
  const dropSum = props.drops.reduce((acc, cur) => acc + cur.price, 0);
  return +GlobalUtils.Prices.toFixedDefault(dropSum);
});

// длина искусственного массива, дополненного до нужного кол-ва айтемов
const lengthCreatedArray = ref(0);

// Функция для перемешки предметов
const shuffle = () => {
  const arr = getFilledArray();

  lengthCreatedArray.value = arr.length;

  let currentIndex = arr.length;
  let randomIndex;

  while (currentIndex > 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [arr[currentIndex], arr[randomIndex]] = [arr[randomIndex], arr[currentIndex]];
  }

  return arr;
};

const getFilledArray = () => {
  const arr = [...props.items];
  if (arr.length > SPINNER_ITEMS_ARRAY_MIN_LENGTH || !arr.length) return arr;

  const cycles = Math.ceil(SPINNER_ITEMS_ARRAY_MIN_LENGTH / arr.length);

  for (let i = 0; i < cycles; i++) {
    arr.push(...props.items.map((el) => ({ ...el })));
  }

  return arr.slice(0, SPINNER_ITEMS_ARRAY_MIN_LENGTH);
};

const itemSize = computed(() => changeXL(112, 84));

const scrollEmitter = useEmitScroll(
  // @ts-ignore
  emits,
  {
    gapInDrop: 4,
    itemSize,
  },
  'y',
);

const beforeTransition = () => {
  if (showEnd.value) {
    roundSum.value = 0;
  }
};

const afterTransition = (el: Element) => {
  if (el === spinnerWrapper.value && needToRoll.value) {
    needToRoll.value = false;
    nextTick(animate);
    return;
  }

  if (showEnd.value) {
    startSumAnimation(lootPrice.value, 0);
  }
};

const carouselTiming: KeyframeAnimationOptions = {
  // добавляю задержку между стартом рулеток
  delay: 0,
  direction: 'normal',
  duration: 12000,
  easing: 'cubic-bezier(0.075, 0.9, 0.4, 1)',
  endDelay:
    BattlesSpinnerTimeouts.animationDropShow +
    BattlesSpinnerTimeouts.afterStopInRandomPlace +
    BattlesSpinnerTimeouts.growShrinkEndRoundCircle * 2 +
    BattlesSpinnerTimeouts.noChangeRoundCircle -
    3 * BattlesSpinnerTimeouts.defaultDelay,
  fill: 'both',
  iterations: 1,
};

const isAnimateDrop = ref(false);
// прячу тень
const isPossibleShadow = ref(false);
const goMiddleLine = ref(false);

// рандомный сдвиг при остановке, установим значение из композабла
const topIndent = ref(0);
// index победного айтема
const indexWonItem = computed(() => lengthCreatedArray.value - ITEMS_AFTER_END - 1);

let dropTimeout: ReturnType<typeof setTimeout>;
let goMiddleLineTimeout: ReturnType<typeof setTimeout>;
let resetDropTimeout: ReturnType<typeof setTimeout>;

// круги в конце раунда
const isShowEndRoundCircle = ref(false);
// полоса награды в конце раунда
const isShowEndRoundRewardLine = ref(false);

// Функция для анимации прокрутки
const animate = () => {
  if (!spinner.value || !line.value) return;
  // для рассинхрона - делаю эмит события старта первой рулетки
  if (props.playerSlot === 1) emits('start-round');
  isAnimateDrop.value = false;
  goMiddleLine.value = false;
  isSpinnerHidden.value = false;

  // анимация возврата карточки от рандомного значения к середине линии (выравнивание)
  goMiddleLineTimeout = setTimeout(() => (goMiddleLine.value = true), BattlesSpinnerTimeouts.defaultSpinner);

  // время от начала анимации рулетки до начала анимации показа дропа (запуск после кручение + выравнивание рулетки посередине)
  dropTimeout = setTimeout(() => {
    isAnimateDrop.value = true;
    isPossibleShadow.value = true;
  }, BattlesSpinnerTimeouts.defaultSpinner + BattlesSpinnerTimeouts.afterStopInRandomPlace);
  // старт показа кругов итога раунда и баттлов (и конец анимации дропа)

  setTimeout(
    () => {
      isSpinnerHidden.value = true;
      isShowEndRoundCircle.value = true;
      isShowEndRoundRewardLine.value = true;
      isPossibleShadow.value = false;
      if (props.isLastRound) emits('end-battle-animate-inventory');
    },
    BattlesSpinnerTimeouts.defaultSpinner +
      BattlesSpinnerTimeouts.animationDropShow +
      BattlesSpinnerTimeouts.afterStopInRandomPlace -
      3 * BattlesSpinnerTimeouts.defaultDelay, // defaultDelay - для запуска анимации чуть раньше
  );

  // конец показа кругов раунда (время на анимацию кругов разбито на 3 части - уширение/уменьшения, и когда они не меняются)
  setTimeout(
    () => {
      isShowEndRoundCircle.value = false;
      isShowEndRoundRewardLine.value = false;
    },
    BattlesSpinnerTimeouts.defaultSpinner +
      BattlesSpinnerTimeouts.animationDropShow +
      BattlesSpinnerTimeouts.afterStopInRandomPlace +
      BattlesSpinnerTimeouts.growShrinkEndRoundCircle * 2 +
      BattlesSpinnerTimeouts.noChangeRoundCircle * 2 -
      3 * BattlesSpinnerTimeouts.defaultDelay,
  );

  const spinnerAlgorithm = useSpinnerAlgorithm({
    carouselElement: spinner,
    carouselLine: line,
    gapInDrop: 4,
    itemCount: lengthCreatedArray.value,
    itemEndCount: ITEMS_AFTER_END,
    itemSize,
  });

  topIndent.value = spinnerAlgorithm.topIndent;

  const endScrollEmitter = scrollEmitter.emitScroll(spinner.value, null, props.items.length, line.value);

  const animation = spinner.value.animate(
    [
      {
        transform: spinnerAlgorithm.animation(),
      },
    ],
    { ...carouselTiming, duration: props.duration },
  );

  animation.addEventListener('finish', () => {
    endScrollEmitter();
    spinnerEnd.value = true;
    finishDefault();
  });
};

// сдвиг к середине карточки с учетом рандомного сдвига, получаемого из композабла
const randomTop = computed(() => itemSize.value / 2 - topIndent.value);

const getColorForItem = (idx: number) => {
  return appConfig.tastydrop.default.colorPalette.skinsRarity[
    dropsShow.value[idx]?.quality.toLowerCase() as keyof typeof appConfig.tastydrop.default.colorPalette.skinsRarity
  ];
};

const stylesItem = computed(() => {
  return (idx: number) => ({
    '--rare-color-inner': `linear-gradient(33.02deg, ${getColorForItem(idx)} 0%, rgba(211, 45, 230, 0) 49.61%)`,
  });
});

const prizeLineColor = computed(() => {
  return props.isWon ? BattlesColors.WIN : BattlesColors.LOSE;
});

const itemClasses = computed(() => {
  return (idx: number) => ({
    'all-item': goMiddleLine.value,
    'other-item': isAnimateDrop.value,
    'spinner-item': true,
    'winner-item': idx === indexWonItem.value && isAnimateDrop.value,
  });
});

const lineClasses = computed(() => ({
  'cases-spinner__spin-line': true,
  'hidden': isSpinnerHidden.value,
  'spin-line__less': isAnimateDrop.value,
}));

const spinnerClasses = computed(() => ({
  'cases-spinner__spin-main': true,
  'hidden': isSpinnerHidden.value,
}));

const spinnerWrapperClasses = computed(() => [
  'cases-spinner__spin',
  { 'hidden-shadow': isAnimateDrop.value, 'shadow-anime': !isAnimateDrop.value },
]);

const finishDefault = () => {
  showDrop.value = true;
  clearTimeout(dropTimeout);
  clearTimeout(goMiddleLineTimeout);
  clearTimeout(resetDropTimeout);
  emits('end-spin');

  setTimeout(
    () => {
      // задержка для показа увеличенного итога последнего раунда
      isShowEndRoundCircle.value = false;
      isShowEndRoundRewardLine.value = false;
      emits('end');
    },
    props.isLastRound ? BattlesSpinnerTimeouts.noChangeLastRoundCircle : 0,
  );
};

const declareImageOnItem = (item: IRoundSkin | ISimplifiedPreviewItem) => {
  return (item as ISimplifiedPreviewItem).image || (item as IRoundSkin).image || '';
};

const declareColorOfItem = (item: IRoundSkin | ISimplifiedPreviewItem) => {
  return GlobalUtils.Colors.getColorsRarity(
    (item as ISimplifiedPreviewItem).quality || (item as IRoundSkin).quality || '',
  );
};

watch(
  () => props.number,
  (round) => {
    if (!round) return;
    showDropTimeout.value && clearTimeout(showDropTimeout.value);
    showDropTimeout.value = null;
    showDrop.value = false;

    spinnerEnd.value = false;
  },
);

watch(
  () => props.duration,
  (time) => {
    if (!time) return;
    if (!spinnerWrapper.value) {
      needToRoll.value = true;
      return;
    }

    nextTick(animate);
  },
  {
    immediate: true,
  },
);

const randomTopPx = computed(() => `${randomTop.value}px`);
const rareColorOuter = computed(() => `0 0 24px 4px ${getColorForItem(indexWonItem.value)}`);
const rareBg = computed(() => getColorForItem(indexWonItem.value));
const showDropMs = computed(() => `${BattlesSpinnerTimeouts.animationDropShow}ms`);
const growRoundsMs = computed(() => `${BattlesSpinnerTimeouts.growShrinkEndRoundCircle}ms`);
const outerColor = computed(() => {
  if (props.isLastRound) return BattlesColors.END_BATTLE_7;
  return props.isWon ? BattlesColors.WIN_7 : BattlesColors.LOSE_7;
});
const innerColor = computed(() => {
  if (props.isLastRound) return BattlesColors.END_BATTLE_15;
  return props.isWon ? BattlesColors.WIN_15 : BattlesColors.LOSE_15;
});
</script>

<style scoped lang="scss" src="./CasesSpinner.scss" />
<style scoped lang="scss">
.cases-spinner {
  --random-top: v-bind(randomTopPx);
  --rare-color-outer: v-bind(rareColorOuter);
  --rare-bg: v-bind(rareBg);
  --show-drop-ms: v-bind(showDropMs);
  --grow-rounds-ms: v-bind(growRoundsMs);
}
</style>
